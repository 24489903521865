.add-project-container {
  width: 800px;
  margin: 115px auto 20px auto;
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.add-project-container h1 {
  text-align: center;
  color: #000000;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
}
.form-group {
  margin-bottom: 1.5rem;
}
.form-group label {
  display: block;
  font-weight: bold;
  color: #555;
  margin-bottom: 0.5rem;
}
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border: 1px solid #DDDDDD;
  border-radius: 8px!important;
  box-sizing: border-box;
  resize: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #489BF4;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
}
.add-project-button {
  width: 100%;
  padding: 15px;
  font-size: 15px;
  color: #FFFFFF;
  background-color: #489BF4;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.add-project-button:hover {
  background-color: #489BF495;
}
@media only screen and (max-device-width: 960px), only screen and (max-width: 960px) {
  .add-project-container{
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
}