.invite-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #F7F7F7;
}
.invite-logo .logo {
  width: 150px;
  margin-bottom: 20px;
}
.invite-message {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333333;
}
.loader {
  border: 4px solid #F3F3F3;
  border-top: 4px solid #3498DB;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-top: 20px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}