.register-container {
  width: 100%;
  max-width: 400px;
  margin: 100px auto;
  padding: 25px 30px 40px 30px;
  border-radius: 15px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  color: #000000;
  box-sizing: border-box;
}
.register-container h1 {
  text-align: center;
  color: #000000;
  margin-bottom: 50px;
  font-size: 26px;
}
.message {
  text-align: center;
  color: #489BF4;
  font-weight: bold;
  margin-bottom: 1rem;
}
.form-group {
  margin-bottom: 15px;
}
.form-group label {
  display: block;
  font-weight: bold;
  color: #555;
  margin-bottom: 0.5rem;
}
.form-group input {
  width: 100%;
  padding: 15px;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  font-size: 16px;
  background-color: #FFFFFF;
  transition: border 0.3s ease;
  box-sizing: border-box;
}
.form-group input:focus {
  outline: none;
  border-color: #489BF4;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
}
.register-button {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: #FFFFFF!important;
  background-color: #489BF4;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.register-button:hover {
  background-color: #489BF495;
}