
.login-container {
  width: 100%;
  max-width: 400px;
  margin: 100px auto;
  padding: 40px 30px;
  border-radius: 15px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  color: #000000;
  box-sizing: border-box;
}
.logo img {
  width: 80px;
}
.login-container h2 {
  font-size: 1.8em;
  color: #000000;
  margin-bottom: 50px;
}
.input-group {
  margin-bottom: 20px;
  text-align: left;
}
.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #555;
}
.input-group input {
  width: 100%;
  padding: 15px;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  font-size: 1em;
  background-color: #FFFFFF;
  transition: border 0.3s ease;
  box-sizing: border-box;
}
.input-group input:focus {
  outline: none;
  border: 1px solid #489BF4;
}
.login-container button {
  width: 100%;
  padding: 15px;
  background-color: #489BF4;
  color: #FFFFFF!important;
  border: none;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}
.login-container button:hover {
  background-color: #489BF4;
}
.error-message {
  color: #D9534F;
  margin-bottom: 10px;
  font-size: 0.9em;
}
.register-prompt {
  margin-top: 30px;
  font-size: 0.9em;
}
.register-link {
  background: none;
  border: none;
  color: #489BF4;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  transition: color 0.3s ease;
}
.register-link:hover {
  color: #489BF495;
}
.invite-alert{
  background: #489BF420;
  border: 1px solid #489BF4;
  color: #489BF4;
  padding: 20px;
  width: 100%;
  margin: 0 0 20px 0;
  box-sizing: border-box;
  border-radius: 12px;
  line-height: 1.5;
  font-size: 14px;
}