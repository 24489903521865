.profile-container {
  width: 800px;
  margin: 115px auto 20px auto;
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
h1, h2 {
  text-align: center;
  color: #333333;
  margin-bottom: 20px;
}
.message {
  color: #489BF4;
  text-align: center;
  margin-bottom: 15px;
}
.form-group {
  margin-bottom: 15px;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #555555;
}
.form-group input {
  width: 100%;
  padding: 15px;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  font-size: 14px;
}
.update-button {
  width: 100%;
  padding: 15px;
  background-color: #489BF4;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  margin-top: 10px;
}
.update-button:hover {
  background-color: #489BF4;
}
@media only screen and (max-device-width: 960px), only screen and (max-width: 960px) {
  .profile-container{
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
}