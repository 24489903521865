.header {
  background-color: #489BF4;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.header-container {
    width: 800px;
    height: 75px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-logo .logo {
  height: 40px;
}
.logo-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  top: 1px;
}
.header-title {
  font-size: 1.5em;
  margin: 0;
}
.header-buttons {
  display: flex;
  gap: 15px;
}
.header-button {
    display: flex;
    gap: 10px;
    align-items: center;
    border: none;
    color: white;
    font-size: 1em;
    cursor: pointer;
    background: #FFFFFF20;
    padding: 8px 20px;
    border-radius: 8px;
}
.button-label {
    font-size: 15px;
}
@media only screen and (max-device-width: 960px), only screen and (max-width: 960px) {
  .header-container{
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .header-button{
    padding: 8px 15px;
  }
  .header-button>span{
    font-size: 13px;
  }
}