.bug-detail-container {
  padding: 30px;
  width: 800px;
  margin: 115px auto 20px auto;
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  box-sizing: border-box;
}
.bug-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.bug-header h1 {
  font-size: 24px;
  margin: 0;
  color: #333;
}
.status-badge {
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}
.status-badge.completed {
  background-color: #4CAF50;
  color: #FFFFFF;
}
.status-badge.incomplete {
  background-color: #ff6b6b;
  color: #FFFFFF;
}
.bug-info p {
  font-size: 16px;
  line-height: 1.5;
}
.bug-creator {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}
.bug-attachments h2,
.bug-logs h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 2px solid #DDDDDD;
  padding-bottom: 5px;
}
.attachment-item {
  background-color: #F1F1F1;
  padding: 20px;
  margin-bottom: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  border: 2px solid #489BF4;
}
.attachment-item a {
  text-decoration: none;
  color: #489BF4;
  font-weight: bold;
  transition: color 0.3s;
}
.attachment-item a:hover {
  color: #489BF4;
}
.bug-logs .log-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FAFAFA;
  padding: 12px 15px;
  margin-bottom: 8px;
  border-radius: 8px;
  transition: transform 0.2s ease;
}
.bug-logs .log-item:hover {
  transform: scale(1.02);
}
.log-item p {
  margin: 0;
  font-weight: bold;
  color: #444444;
}
.log-timestamp,
.log-user {
  font-size: 12px;
  color: #888888;
}
@media (max-width: 768px) {
  .bug-detail-container {
    padding: 15px;
  }
  .bug-header h1 {
    font-size: 20px;
  }
  .status-badge {
    padding: 6px 12px;
    font-size: 12px;
  }
  .bug-attachments h2,
  .bug-logs h2 {
    font-size: 16px;
  }
}
@media only screen and (max-device-width: 960px), only screen and (max-width: 960px) {
  .bug-detail-container{
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
}