.bugs-container {
  width: 800px;
  margin: 115px auto 20px auto;
}
.bugs-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 20px;
}
.add-bug-button {
  width: 100%;
  color: #999999;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 21px 25px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.bug-info{
  width: 100%;
  display: flex;
  gap: 20px;
}
.bug-info-left{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}
.bug-info-right{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
.bug-info-right>div{
  display: flex;
  gap: 20px;
}
.bug-info-right-mail{
  color: #D3D3D3;
  font-size: 12px;
}
.bug-info-right-mail:hover{
  color: #489BF4;
}
.bug-info-right button{
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
}
.bug-info-right span{
  color: #9B9B9B;
}
.bug-list {
  display: flex;
  flex-direction: column;
}
.version-group {
  margin-bottom: 20px;
}
.version-group h2 {
  margin: 0 0 20px;
  font-size: 20px;
  color: #000000;
}
.bug-card {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 12px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.custom-checkbox {
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  top: 2px;
}
.custom-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}
.custom-checkbox .checkmark {
  width: 20px;
  height: 20px;
  background-color: #E3E3E3;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  padding: 3px;
}
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #489BF4;
}
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 4px;
  width: 6px;
  height: 12px;
  border: solid #FFFFFF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}
.bug-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
}
.bug-details {
  font-size: 13px;
  color: #999999;
  margin: 0;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 16px;
  width: 90%;
  max-width: 600px;
}
.modal-content>p{
  text-align: center;
  line-height: 1.5;
  padding: 10px 20px;
}
.modal-input {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  resize: none;
}
.modal-input:focus{
  outline-color: #489BF4;
}
.modal-add-button {
  padding: 15px;
  background-color: #489BF4;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
}
.modal-close-button {
  padding: 15px;
  background-color: #FF4D4F;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
  font-size: 15px;
}
.modal-secondary-button {
  padding: 15px;
  background-color: #D3D3D3;
  color: #000000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
  font-size: 15px;
}
.add-version-button, .add-user-button {
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}
.modal p {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
}
.bugs-header-left{
  min-width: 250px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #DCDCDC;
  padding: 10px;
  border-radius: 12px;
}
.bugs-header-left h1{
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}
.bugs-header-left div {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #489BF430;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #489BF4;
  font-size: 13px;
}
.file-upload-section {
  display: flex;
  align-items: center;
  gap: 10px;
}
.file-input {
  padding: 10px;
  font-size: 14px;
  color: #555555;
}
.file-upload-button {
  padding: 8px 15px;
  background-color: #489BF4;
  color: #FFFFFF;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
}
.file-upload-button:hover {
  background-color: #489BF4;
}
.loading-spinner {
  border: 4px solid #F3F3F3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.upload-status {
  margin-top: 10px;
}
.status-message {
  font-size: 14px;
  font-weight: bold;
}
.success {
  color: #28A745;
}
.error {
  color: #DC3545;
}
.uploaded-file-link a {
  color: #007BFF;
  text-decoration: none;
}
.versionTitle{
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 0 0  20px 0;
}
.versionTitle>h2{
  margin: 0;
}
.versionTitle>button{
  margin: 0;
  border: 0;
  padding: 0;
  cursor: pointer;
  background: transparent;
}
.versionTitle>div{
  width: 100%;
  height: 1px;
  background-color: #D3D3D3;
}
.versionTitle>div:first-child{
  margin: 0 30px 0 0;
}
.versionTitle>div:last-child{
  margin: 0 0 0 30px;
}
.modal-preview{
  width: 100%!important;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 12px;
}
.modal-preview:focus{
  outline-color: #489BF4;
}
.search-bar{
  width: 100%;
}
.search-input {
  width: 100%;
  max-width: 800px;
  padding: 21px 25px;
  margin: 0 auto 20px auto;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.search-input:focus {
  outline-color: #489BF4;
}
@media only screen and (max-device-width: 960px), only screen and (max-width: 960px) {
  .bugs-container{
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .bugs-header-left{
    min-width: 150px;
  }
}