.dashboard-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.header-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}
.project-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  margin: 115px auto 20px auto;
}
.project-card {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  cursor: pointer;
  transition: background 0.2s;
  box-sizing: border-box;
}
.project-card:hover {
  background-color: #F0F0F0;
}
.project-initials {
  min-width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: #489BF420;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #489BF4;
  font-size: 1.2rem;
  margin-right: 1rem;
  border: 0.5px solid #489BF4;
}
.project-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.project-name {
  margin: 0;
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}
.project-description {
  margin: 0;
  font-size: 0.9rem;
  color: #777;
}
.arrow {
  font-size: 1.5rem;
  color: #ccc;
}
.search-bar{
  width: 100%;
}
.search-input {
  width: 100%;
  max-width: 800px;
  padding: 21px 25px;
  margin: 0 auto 20px auto;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.search-input:focus {
  outline-color: #489BF4;
}
.active-work{
  color: #489BF4;
  font-size: 12px;
  width: 10%;
  border: 0.5px solid #489BF4;
  padding: 3px 10px;
  text-align: center;
  border-radius: 5px;
  margin: 0 15px 0 0;
}
@media only screen and (max-device-width: 960px), only screen and (max-width: 960px) {
  .project-list{
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
}